<template>
  <v-app>
    <!-- <home-system-bar /> -->

    <account-app-bar />

    <account-view />

    <account-footer />
  </v-app>
</template>

<script>
  export default {
    name: 'HomeLayout',

    components: {
      // HomeSystemBar: () => import('@/layouts/account/SystemBar'),
      AccountAppBar: () => import('@/layouts/account/AppBar'),
      AccountView: () => import('@/layouts/account/View'),
      AccountFooter: () => import('@/layouts/account/Footer'),
    },
  }
</script>
